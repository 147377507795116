/* eslint-disable no-constructor-return */
import { createApp } from 'vue';
import _ from 'lodash';

export default function ProxyVue(mixBoot = () => {}) {
  return class ProxyVueApp {
    static $set = _.set;

    static set = _.set;

    static delete = _.unset;

    static $delete = _.unset;

    static components = {};

    static component = (name, config) => { ProxyVueApp.components[name] = config; };

    constructor(options = {}) {
      if (!options.el) {
        return this;
      }
      const data = () => {
        if (!_.isFunction(options.data) && _.isObject(options.data)) {
          return options.data;
        }
        if (_.isFunction(options.data)) {
          return options.data();
        }
        return {};
      };

      const methods = {
        ...options.methods,
        $set: _.set,
        set: _.set,
        delete: _.unset,
        $delete: _.unset,
      };

      const app = createApp({ ...options, data, methods });
      mixBoot(app);
      _.forEach(ProxyVueApp.components, (config, name) => {
        app.component(name, config);
      });
      return app.mount(options.el);
    }
  };
}
