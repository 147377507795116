import { defineAsyncComponent, createApp } from 'vue';

const VContingentCreate = defineAsyncComponent(() => import('@app_mektep/views/VContingentApp/VContingentCreate/VContingentCreate.vue'));

export default function (mixBoot) {
  if (document.getElementById('v-contingent-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-contingent-create', VContingentCreate);
    VueApp.mount('#v-contingent-app');
    window.VContingentApp = VueApp;
  }
}
