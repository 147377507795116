import componentsViews from '@app_mektep/components/Views';
import componentsIndex from '@app_mektep/components/Index';
import componentsModals from '@app_mektep/components/Modals';
import componentsLegacy from '@app_mektep/components/Legacy';

export default function (Vue) {
  componentsViews(Vue);
  componentsIndex(Vue);
  componentsModals(Vue);
  componentsLegacy(Vue);
}
