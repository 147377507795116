// Either pass in a list of refs to watch, or an object, whose keys are the name, and the values are a function which returns the element to watch like: { container: component => component.refs.parent.el }. this.$refs will be passed to the function as refs.
// if container is passed as a string, the component will have 4 new pieces of data kept in sync with the dom: this.containerWidth, this.containerHeight, thisContainerX and this.containerY.

import {
  nextTick, ref,
} from 'vue';

function isObjectArgs(args) {
  return _.isObject(args);
}

function determineRefKeys(args) {
  if (isObjectArgs(args)) {
    return Object.keys(args);
  }
  return args;
}

function observerKey(refItm) {
  return `${refItm}ComputedDimensionsObserver`;
}

function widthKey(refItm) {
  return `${refItm}Width`;
}

function heightKey(refItm) {
  return `${refItm}Height`;
}

function xKey(refItm) {
  return `${refItm}X`;
}

function yKey(refItm) {
  return `${refItm}Y`;
}

export default function (args) {
  const refKeys = determineRefKeys(args);

  const obj = {};
  for (const refKey of refKeys) {
    obj[observerKey(refKey)] = null;
    obj[widthKey(refKey)] = 0;
    obj[heightKey(refKey)] = 0;
    obj[xKey(refKey)] = 0;
    obj[yKey(refKey)] = 0;
  }

  const computedDimensions = ref(obj);

  const elFromRefKey = (refKey) => {
    const el = args[refKey].value;

    return el?.$el || el; // if its a vuecomponent ref, get the actual element, otherwise its a normal element ref so carry on
  };

  const setDimensions = (refKey) => {
    const el = elFromRefKey(refKey);
    if (el) {
      const rect = el.getBoundingClientRect();
      computedDimensions.value[widthKey(refKey)] = rect.width;
      computedDimensions.value[heightKey(refKey)] = rect.height;
      computedDimensions.value[xKey(refKey)] = rect.x;
      computedDimensions.value[yKey(refKey)] = rect.y;
    }
  };

  function mounted() {
    nextTick(() => {
      for (const refKey of refKeys) {
        computedDimensions.value[observerKey(refKey)] = new ResizeObserver(
          () => setDimensions(refKey),
        );
        const el = elFromRefKey(refKey);

        if (el) {
          computedDimensions.value[observerKey(refKey)].observe(el);
          setDimensions(refKey);
        } else {
          console.warn(
            `computedDimensions mixin could not find a ref element with key: ${refKey}`,
          );
        }
      }
    });
  }

  function beforeUnmount() {
    for (const refKey of refKeys) {
      computedDimensions.value[observerKey(refKey)].disconnect();
    }
  }

  return {
    computedDimensions,
    mounted,
    beforeUnmount,
  };
}
