import { h, computed, getCurrentInstance } from 'vue';

import useSize, { useSizeProps } from '../../composables/private.use-size/use-size.js';

import { createComponent } from '../../utils/private.create/create.js';
import { hSlot, hMergeSlot } from '../../utils/private.render/render.js';

const defaultViewBox = '0 0 24 24';

const sameFn = (i) => i;
const ionFn = (i) => `ionicons ${i}`;

const libMap = {
  'mdi-': (i) => `mdi ${i}`,
  'icon-': sameFn, // fontawesome equiv
  'bt-': (i) => `bt ${i}`,
  'eva-': (i) => `eva ${i}`,
  'ion-md': ionFn,
  'ion-ios': ionFn,
  'ion-logo': ionFn,
  'iconfont ': sameFn,
  'ti-': (i) => `themify-icon ${i}`,
  'bi-': (i) => `bootstrap-icons ${i}`,
};

const matMap = {
  o_: '-outlined',
  r_: '-round',
  s_: '-sharp',
};

const symMap = {
  sym_o_: '-outlined',
  sym_r_: '-rounded',
  sym_s_: '-sharp',
};

const libRE = new RegExp(`^(${Object.keys(libMap).join('|')})`);
const matRE = new RegExp(`^(${Object.keys(matMap).join('|')})`);
const symRE = new RegExp(`^(${Object.keys(symMap).join('|')})`);
const mRE = /^[Mm]\s?[-+]?\.?\d/;
const imgRE = /^img:/;
const svgUseRE = /^svguse:/;
const ionRE = /^ion-/;
const faRE = /^(fa-(sharp|solid|regular|light|brands|duotone|thin)|[lf]a[srlbdk]?) /;

export default createComponent({
  name: 'QIcon',

  props: {
    ...useSizeProps,

    tag: {
      type: String,
      default: 'i',
    },

    name: String,
    color: String,
    left: Boolean,
    right: Boolean,
    light: Boolean,
    bold: Boolean,
    boldest: Boolean,
    iconClass: {
      type: String,
      default: 's-ico',
    },
  },

  setup(props, { slots }) {
    const { proxy: { $q } } = getCurrentInstance();
    const sizeStyle = useSize(props);

    const classes = computed(() => `
    ${props.iconClass}
    ${props.left === true ? ' s-pos-on-left' : ''}
    ${props.right === true ? ' s-pos-on-right' : ''}
    ${props.color !== void 0 ? ` s-c-${props.color}` : ''}
    ${props.boldest ? ' s-ico--boldest' : ''}
    ${props.light ? ' s-ico--light' : ''}
     ${props.bold ? ' s-ico--bold' : ''}
    `);

    const type = computed(() => {
      let cls;
      const icon = props.name;

      if (icon === 'none' || !icon) {
        return { none: true };
      }

      if (icon.includes(`${props.iconClass}--thm-`)) {
        cls = `${icon}`;
      } else {
        cls = `${props.iconClass}--thm-${icon}`;
      }

      const content = ' ';

      return {
        cls,
        content,
      };
    });

    return () => {
      const data = {
        class: classes.value,
        style: sizeStyle.value,
        'aria-hidden': 'true',
        role: 'presentation',
      };

      if (type.value.none === true) {
        return h(props.tag, data, hSlot(slots.default));
      }

      if (type.value.img === true) {
        return h(props.tag, data, hMergeSlot(slots.default, [
          h('img', { src: type.value.src }),
        ]));
      }

      if (type.value.svg === true) {
        return h(props.tag, data, hMergeSlot(slots.default, [
          h('svg', {
            viewBox: type.value.viewBox || '0 0 24 24',
          }, type.value.nodes),
        ]));
      }

      if (type.value.svguse === true) {
        return h(props.tag, data, hMergeSlot(slots.default, [
          h('svg', {
            viewBox: type.value.viewBox,
          }, [
            h('use', { 'xlink:href': type.value.src }),
          ]),
        ]));
      }

      if (type.value.cls !== void 0) {
        data.class += ` ${type.value.cls}`;
      }

      return h(props.tag, data, hMergeSlot(slots.default, [
        type.value.content,
      ]));
    };
  },
});
