import {
  h, ref, computed, Transition, nextTick, onActivated, onDeactivated, onBeforeUnmount, onMounted, getCurrentInstance,
} from 'vue';

import QIcon from '../../components/icon/QIcon.js';
import QSpinner from '../../components/spinner/QSpinner.js';

import useId from '../use-id/use-id.js';
import useSplitAttrs from '../use-split-attrs/use-split-attrs.js';
import useDark, { useDarkProps } from '../private.use-dark/use-dark.js';
import useValidate, { useValidateProps } from '../private.use-validate/use-validate.js';

import { hSlot } from '../../utils/private.render/render.js';
import { prevent, stopAndPrevent } from '../../utils/event/event.js';
import { addFocusFn, removeFocusFn } from '../../utils/private.focus/focus-manager.js';

export function fieldValueIsFilled(val) {
  return val !== void 0
    && val !== null
    && (`${val}`).length !== 0;
}

export const useNonInputFieldProps = {
  ...useDarkProps,
  ...useValidateProps,

  label: String,
  stackLabel: Boolean,
  hint: String,
  hideHint: Boolean,
  prefix: String,
  suffix: String,

  labelColor: String,
  color: String,
  bgColor: String,

  filled: Boolean,
  outlined: Boolean,
  borderless: Boolean,
  standout: [Boolean, String],

  square: Boolean,

  loading: Boolean,

  labelSlot: Boolean,

  bottomSlots: Boolean,
  hideBottomSpace: Boolean,

  rounded: Boolean,
  dense: Boolean,
  itemAligned: Boolean,

  counter: Boolean,

  clearable: Boolean,
  clearIcon: String,

  disable: Boolean,
  readonly: Boolean,
  preview: Boolean,
  required: Boolean,

  autofocus: Boolean,

  withoutAutoHeight: Boolean,

  for: String,
  fieldCustomClass: [String, Array, Object],
  fieldInnerStyle: [String, Array, Object],
  borderlessParent: Boolean,
  twoField: Boolean,
};

export const useFieldProps = {
  ...useNonInputFieldProps,
  maxlength: [Number, String],
};

export const useFieldEmits = ['update:modelValue', 'clear', 'focus', 'blur'];

export function useFieldState({ requiredForAttr = true, tagProp, changeEvent = false } = {}) {
  const { props, proxy } = getCurrentInstance();

  const isDark = useDark(props, proxy.$q);
  const targetUid = useId({
    required: requiredForAttr,
    getValue: () => props.for,
  });

  return {
    requiredForAttr,
    changeEvent,
    tag: tagProp === true
      ? computed(() => props.tag)
      : { value: 'label' },

    isDark,

    editable: computed(() => props.disable !== true && props.readonly !== true),

    innerLoading: ref(false),
    focused: ref(false),
    hasPopupOpen: false,

    splitAttrs: useSplitAttrs(),
    targetUid,

    rootRef: ref(null),
    targetRef: ref(null),
    controlRef: ref(null),

    /**
     * user supplied additionals:

     * innerValue - computed
     * floatingLabel - computed
     * inputRef - computed

     * fieldClass - computed
     * hasShadow - computed

     * controlEvents - Object with fn(e)

     * getControl - fn
     * getInnerAppend - fn
     * getControlChild - fn
     * getShadowControl - fn
     * showPopup - fn
     */
  };
}

export default function (state) {
  const {
    props, emit, slots, attrs, proxy,
  } = getCurrentInstance();
  const { $q } = proxy;

  let focusoutTimer = null;

  if (state.hasValue === void 0) {
    state.hasValue = computed(() => fieldValueIsFilled(props.modelValue));
  }

  if (state.emitValue === void 0) {
    state.emitValue = (value) => {
      emit('update:modelValue', value);
    };
  }

  if (state.controlEvents === void 0) {
    state.controlEvents = {
      onFocusin: onControlFocusin,
      onFocusout: onControlFocusout,
    };
  }

  Object.assign(state, {
    clearValue,
    onControlFocusin,
    onControlFocusout,
    focus,
  });

  if (state.computedCounter === void 0) {
    state.computedCounter = computed(() => {
      if (props.counter !== false) {
        const len = typeof props.modelValue === 'string' || typeof props.modelValue === 'number'
          ? (`${props.modelValue}`).length
          : (Array.isArray(props.modelValue) === true ? props.modelValue.length : 0);

        const max = props.maxlength !== void 0
          ? props.maxlength
          : props.maxValues;

        return len + (max !== void 0 ? ` / ${max}` : '');
      }
    });
  }

  const {
    isDirtyModel,
    hasRules,
    hasError,
    errorMessage,
    resetValidation,
  } = useValidate(state.focused, state.innerLoading);

  const floatingLabel = state.floatingLabel !== void 0
    ? computed(() => props.stackLabel === true || state.focused.value === true || state.floatingLabel.value === true)
    : computed(() => props.stackLabel === true || state.focused.value === true || state.hasValue.value === true);

  const shouldRenderBottom = computed(() => props.bottomSlots === true
    || props.hint !== void 0
    || hasRules.value === true
    || props.counter === true
    || props.error !== null);

  const styleType = computed(() => {
    if (props.filled === true) { return 'filled'; }
    if (props.outlined === true) { return 'outlined'; }
    if (props.borderless === true) { return 'borderless'; }
    if (props.borderlessParent === true) { return 'borderless-parent'; }
    if (props.standout) { return 'standout'; }
    return 'standard';
  });

  const classes = computed(() => `sn-field--${styleType.value}${
    state.fieldClass !== void 0 ? ` ${state.fieldClass.value}` : ''
  }${props.rounded === true ? ' sn-field--rounded' : ''
  }${props.square === true ? ' sn-field--square' : ''
  }${floatingLabel.value === true ? ' sn-field--float' : ''
  }${hasLabel.value === true ? ' sn-field--labeled' : ''
  }${props.dense === true ? ' sn-field--dense' : ''
  }${props.twoField === true ? ' sn-field--two-field' : ''
  }${props.itemAligned === true ? ' sn-field--item-aligned sn-item-type' : ''
  }${state.isDark.value === true ? ' sn-field--dark' : ''
  }${!props.withoutAutoHeight && state.getControl === void 0 ? ' sn-field--auto-height' : ''
  }${state.focused.value === true ? ' sn-field--focused' : ''
  }${hasError.value === true ? ' sn-field--error' : ''
  }${props.preview === true ? ' sn-field--preview' : ''
  }${hasError.value === true || state.focused.value === true ? ' sn-field--highlighted' : ''
  }${props.hideBottomSpace !== true && shouldRenderBottom.value === true ? ' sn-field--with-bottom' : ''
  }${props.disable === true ? ' sn-field--disabled' : (props.readonly === true ? ' sn-field--readonly' : '')}`);

  const contentClass = computed(() => `sn-field__control ${
    props.bgColor !== void 0 ? ` s-b-${props.bgColor}` : ''

  }${hasError.value === true
    ? ' s-c-red'
    : (
      typeof props.standout === 'string' && props.standout.length !== 0 && state.focused.value === true
        ? ` ${props.standout}`
        : (props.color !== void 0 ? ` s-c-${props.color}` : '')
    )}`);

  const hasLabel = computed(() => props.labelSlot === true || props.label !== void 0);

  const labelClass = computed(() => `sn-field__label no-pointer-events absolute ellipsis${
    props.labelColor !== void 0 && hasError.value !== true ? ` s-c-${props.labelColor}` : ''}`);

  const controlSlotScope = computed(() => ({
    id: state.targetUid.value,
    editable: state.editable.value,
    focused: state.focused.value,
    floatingLabel: floatingLabel.value,
    modelValue: props.modelValue,
    emitValue: state.emitValue,
  }));

  const attributes = computed(() => {
    const acc = {};

    if (state.targetUid.value) {
      acc.for = state.targetUid.value;
    }

    if (props.disable === true) {
      acc['aria-disabled'] = 'true';
    }

    return acc;
  });

  function focusHandler() {
    const el = document.activeElement;
    let target = state.targetRef !== void 0 && state.targetRef.value;

    if (target && (el === null || el.id !== state.targetUid.value)) {
      target.hasAttribute('tabindex') === true || (target = target.querySelector('[tabindex]'));
      if (target && target !== el) {
        target.focus({ preventScroll: true });
      }
    }
  }

  function focus() {
    addFocusFn(focusHandler);
  }

  function blur() {
    removeFocusFn(focusHandler);
    const el = document.activeElement;
    if (el !== null && state.rootRef.value.contains(el)) {
      el.blur();
    }
  }

  function onControlFocusin(e) {
    if (focusoutTimer !== null) {
      clearTimeout(focusoutTimer);
      focusoutTimer = null;
    }

    if (state.editable.value === true && state.focused.value === false) {
      state.focused.value = true;
      emit('focus', e);
    }
  }

  function onControlFocusout(e, then) {
    focusoutTimer !== null && clearTimeout(focusoutTimer);
    focusoutTimer = setTimeout(() => {
      focusoutTimer = null;

      if (
        document.hasFocus() === true && (
          state.hasPopupOpen === true
          || state.controlRef === void 0
          || state.controlRef.value === null
          || state.controlRef.value.contains(document.activeElement) !== false
        )
      ) {
        return;
      }

      if (state.focused.value === true) {
        state.focused.value = false;
        emit('blur', e);
      }

      then !== void 0 && then();
    });
  }

  function clearValue(e) {
    // prevent activating the field but keep focus on desktop
    stopAndPrevent(e);

    if ($q.platform.is.mobile !== true) {
      const el = (state.targetRef !== void 0 && state.targetRef.value) || state.rootRef.value;
      el.focus();
    } else if (state.rootRef.value.contains(document.activeElement) === true) {
      document.activeElement.blur();
    }

    if (props.type === 'file') {
      // do not let focus be triggered
      // as it will make the native file dialog
      // appear for another selection
      state.inputRef.value.value = null;
    }

    emit('update:modelValue', null);
    state.changeEvent === true && emit('change', null);
    emit('clear', props.modelValue);

    nextTick(() => {
      const isDirty = isDirtyModel.value;
      resetValidation();
      isDirtyModel.value = isDirty;
    });
  }

  function onClearableKeyup(evt) {
    [13, 32].includes(evt.keyCode) && clearValue(evt);
  }

  function getContent() {
    const node = [];

    slots.prepend !== void 0 && node.push(
      h('div', {
        class: 'sn-field__prepend sn-field__marginal',
        key: 'prepend',
        onClick: prevent,
      }, slots.prepend()),
    );

    node.push(
      h('div', {
        class: 'sn-field__control-container sn-anchor--skip',
      }, getControlContainer()),
    );

    hasError.value === true && props.noErrorIcon === false && node.push(
      getInnerAppendNode('error', [
        h(QIcon, { name: $q.iconSet.field.error, color: 'red' }),
      ]),
    );

    if (props.loading === true || state.innerLoading.value === true) {
      node.push(
        getInnerAppendNode(
          'inner-loading-append',
          slots.loading !== void 0
            ? slots.loading()
            : [h(QSpinner, { color: props.color })],
        ),
      );
    } else if (props.clearable === true && state.hasValue.value === true && state.editable.value === true) {
      node.push(
        getInnerAppendNode('inner-clearable-append', [
          h(QIcon, {
            class: 'sn-field__focusable-action',
            name: props.clearIcon || $q.iconSet.field.clear,
            tabindex: 0,
            role: 'button',
            'aria-label': $q.lang.label.clear,
            onKeyup: onClearableKeyup,
            onClick: clearValue,
          }),
        ]),
      );
    }

    slots.append !== void 0 && node.push(
      h('div', {
        class: 'sn-field__append sn-field__marginal',
        key: 'append',
        onClick: prevent,
      }, slots.append()),
    );

    state.getInnerAppend !== void 0 && node.push(
      getInnerAppendNode('inner-append', state.getInnerAppend()),
    );

    state.getControlChild !== void 0 && node.push(
      state.getControlChild(),
    );

    return node;
  }

  function getControlContainer() {
    const node = [];

    props.prefix !== void 0 && props.prefix !== null && node.push(
      h('div', {
        class: 'sn-field__prefix no-pointer-events',
      }, props.prefix),
    );

    if (state.getShadowControl !== void 0 && state.hasShadow.value === true) {
      node.push(
        state.getShadowControl(),
      );
    }

    if (state.getControl !== void 0) {
      node.push(state.getControl());
    }
    // internal usage only:
    else if (slots.rawControl !== void 0) {
      node.push(slots.rawControl(controlSlotScope));
    } else if (slots.control !== void 0) {
      node.push(
        h('div', {
          ref: state.targetRef,
          class: 'sn-field__native',
          tabindex: -1,
          ...state.splitAttrs.attributes.value,
          'data-autofocus': props.autofocus === true || void 0,
        }, slots.control(controlSlotScope.value)),
      );
    }

    props.suffix !== void 0 && props.suffix !== null && node.push(
      h('div', {
        class: 'sn-field__suffix no-pointer-events',
      }, props.suffix),
    );

    return node.concat(hSlot(slots.default));
  }

  function getBottom() {
    let msg; let
      key;

    if (hasError.value === true) {
      if (errorMessage.value !== null) {
        msg = [h('div', { role: 'alert', innerHTML: errorMessage.value })];
        key = `sn--slot-error-${errorMessage.value}`;
      } else {
        msg = hSlot(slots.error);
        key = 'sn--slot-error';
      }
    } else if (props.hideHint !== true || state.focused.value === true) {
      if (props.hint !== void 0) {
        msg = [h('div', props.hint)];
        key = `sn--slot-hint-${props.hint}`;
      } else {
        msg = hSlot(slots.hint);
        key = 'sn--slot-hint';
      }
    }

    const hasCounter = props.counter === true || slots.counter !== void 0;

    if (props.hideBottomSpace === true && hasCounter === false && msg === void 0) {
      return;
    }

    const main = h('div', {
      key,
      class: 'sn-field__messages',
    }, msg);

    return h('div', {
      class: `sn-field__bottom sn-field__bottom--${
        props.hideBottomSpace !== true ? 'animated' : 'stale'}`,
      onClick: prevent,
    }, [
      props.hideBottomSpace === true
        ? main
        : h(Transition, { name: 'sn-transition--field-message' }, () => main),

      hasCounter === true
        ? h('div', {
          class: 'sn-field__counter',
        }, slots.counter !== void 0 ? slots.counter() : state.computedCounter.value)
        : null,
    ]);
  }

  function getInnerAppendNode(key, content) {
    return content === null
      ? null
      : h('div', {
        key,
        class: 'sn-field__append sn-field__marginal sn-anchor--skip',
      }, content);
  }

  function getInner() {
    const node = [];

    if (slots.inner !== void 0) {
      node.push(slots.inner());
    } else {
      node.push(
        h('div', {
          class: 'sn-field__inner',
          style: props.fieldInnerStyle,
        }, [
          h('div', {
            ref: state.controlRef,
            class: contentClass.value,
            tabindex: -1,
            ...state.controlEvents,
          }, getContent()),
        ]),
      );
    }

    return node;
  }

  let shouldActivate = false;

  onDeactivated(() => {
    shouldActivate = true;
  });

  onActivated(() => {
    shouldActivate === true && props.autofocus === true && proxy.focus();
  });

  props.autofocus === true && onMounted(() => {
    proxy.focus();
  });

  onBeforeUnmount(() => {
    focusoutTimer !== null && clearTimeout(focusoutTimer);
  });

  // expose public methods
  Object.assign(proxy, { focus, blur });

  return function renderField() {
    const labelAttrs = state.getControl === void 0 && slots.control === void 0
      ? {
        ...state.splitAttrs.attributes.value,
        'data-autofocus': props.autofocus === true || void 0,
        ...attributes.value,
      }
      : attributes.value;

    return h('div', {
      ref: state.rootRef,
      class: [
        'sn-field__wrapper',
        classes.value,
        attrs.class,
      ],
      style: attrs.style,
      ...labelAttrs,
    }, [
      hasLabel.value === true && h('div', {
        class: labelClass.value,
      }, hSlot(
        slots.label,
        [
          `${this.label}: `,
          this.required && h('i', {}, '*'),
        ],
      )),

      h('div', { class: ['sn-field', props.fieldCustomClass] }, [
        slots.before !== void 0
          ? h('div', {
            class: 'sn-field__before sn-field__marginal',
            onClick: prevent,
          }, slots.before())
          : null,
        getInner(),
        slots.after !== void 0
          ? h('div', {
            class: 'sn-field__after sn-field__marginal',
            onClick: prevent,
          }, slots.after())
          : null,
      ]),
      shouldRenderBottom.value === true
        ? getBottom()
        : null,
    ]);
  };
}
