import { h, computed, getCurrentInstance } from 'vue';

import useDark, { useDarkProps } from '../../composables/private.use-dark/use-dark.js';

import { createComponent } from '../../utils/private.create/create.js';
import { hSlot } from '../../utils/private.render/render.js';

const separatorValues = ['horizontal', 'vertical', 'cell', 'none'];

export default createComponent({
  name: 'QMarkupTable',

  props: {
    ...useDarkProps,

    dense: Boolean,
    flat: Boolean,
    bordered: Boolean,
    square: Boolean,
    wrapCells: Boolean,

    separator: {
      type: String,
      default: 'horizontal',
      validator: (v) => separatorValues.includes(v),
    },
  },

  setup(props, { slots }) {
    const vm = getCurrentInstance();
    const isDark = useDark(props, vm.proxy.$q);

    const classes = computed(() => 'sn-markup-table sn-table__container sn-table__card'
      + ` sn-table--${props.separator}-separator${
        isDark.value === true ? ' sn-table--dark sn-table__card--dark sn-dark' : ''
      }${props.dense === true ? ' sn-table--dense' : ''
      }${props.flat === true ? ' sn-table--flat' : ''
      }${props.bordered === true ? ' sn-table--bordered' : ''
      }${props.square === true ? ' sn-table--square' : ''
      }${props.wrapCells === false ? ' sn-table--no-wrap' : ''}`);

    return () => h('div', {
      class: classes.value,
    }, [
      h('table', { class: 'sn-table' }, hSlot(slots.default)),
    ]);
  },
});
