import GlobalVue from '@common/src/vue';
import { eventBus } from '@common/mixins';

export default {
  install(Vue) {
    const notify = (object = {
      type: '',
      title: '',
      text: '',
    }, position = 'top-right') => {
      // Для переноса в конец выполнения
      setTimeout(() => {
        eventBus.$emit(`v-notify-${position}`, {
          ...object,
          position,
        });
      }, 0);
    };

    Vue.config.globalProperties.$notify = notify;
    GlobalVue.$notify = notify;
    GlobalVue.notify = notify;
  },
};
