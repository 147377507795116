import { defineAsyncComponent, createApp } from 'vue';

const VClubsListView = defineAsyncComponent(() => import('@app_mektep/views/VClubsListView'));

export default function (mixBoot) {
  if (document.getElementById('v-app-clubs-list')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-clubs-list-view', VClubsListView);
    VueApp.mount('#v-app-clubs-list');
    window.vAppClubsList = VueApp;
  }
}
