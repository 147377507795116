export default {
  name: 'themify',
  type: {
    positive: 's-ico--thm-check',
    negative: 's-ico--thm-alert',
    info: 's-ico--thm-info-alt',
    warning: 's-ico--thm-alert',
  },
  arrow: {
    up: 's-ico--thm-arrow-up',
    right: 's-ico--thm-arrow-right',
    down: 's-ico--thm-arrow-down',
    left: 's-ico--thm-arrow-left',
    dropdown: 's-ico--thm-arrow-circle-down',
  },
  chevron: {
    left: 's-ico--thm-angle-left',
    right: 's-ico--thm-angle-right',
  },
  colorPicker: {
    spectrum: 's-ico--thm-brush-alt',
    tune: 's-ico--thm-panel',
    palette: 's-ico--thm-palette',
  },
  pullToRefresh: {
    icon: 's-ico--thm-reload',
  },
  carousel: {
    left: 's-ico--thm-angle-left',
    right: 's-ico--thm-angle-right',
    navigationIcon: 's-ico--thm-control-record',
    thumbnails: 's-ico--thm-layout-slider-alt',
  },
  chip: {
    remove: 's-ico--thm-close',
    selected: 's-ico--thm-check',
  },
  datetime: {
    arrowLeft: 's-ico--thm-angle-left',
    arrowRight: 's-ico--thm-angle-right',
    now: 's-ico--thm-time',
    today: 's-ico--thm-calendar',
  },
  editor: {
    bold: 'format_bold',
    italic: 's-ico--thm-Italic',
    strikethrough: 'strikethrough_s',
    underline: 's-ico--thm-underline',
    unorderedList: 's-ico--thm-list',
    orderedList: 's-ico--thm-list-ol',
    subscript: 'vertical_align_bottom',
    superscript: 'vertical_align_top',
    hyperlink: 's-ico--thm-link',
    toggleFullscreen: 's-ico--thm-fullscreen',
    quote: 's-ico--thm-quote-right',
    left: 's-ico--thm-align-left',
    center: 's-ico--thm-align-center',
    right: 's-ico--thm-align-right',
    justify: 's-ico--thm-align-justify',
    print: 's-ico--thm-printer',
    outdent: 's-ico--thm-angle-double-left',
    indent: 's-ico--thm-angle-double-right',
    removeFormat: 'format_clear',
    formatting: 's-ico--thm-text',
    fontSize: 's-ico--thm-smallcap',
    align: 's-ico--thm-align-left',
    hr: 's-ico--thm-minus',
    undo: 's-ico--thm-share-alt',
    redo: 's-ico--thm-share',
    header: 's-ico--thm-paragraph',
    code: 'code',
    size: 's-ico--thm-smallcap',
    font: 'font_download',
  },
  expansionItem: {
    icon: 's-ico--thm-angle-down',
    denseIcon: 's-ico--thm-arrow-circle-down',
  },
  fab: {
    icon: 's-ico--thm-plus',
    activeIcon: 's-ico--thm-close',
  },
  field: {
    clear: 's-ico--thm-close',
    error: 's-ico--thm-alert',
  },
  pagination: {
    first: 's-ico--thm-angle-double-left',
    prev: 's-ico--thm-angle-left',
    next: 's-ico--thm-angle-right',
    last: 's-ico--thm-angle-double-right',
  },
  rating: {
    icon: 's-ico--thm-star',
  },
  stepper: {
    done: 's-ico--thm-check',
    active: 's-ico--thm-pencil',
    error: 's-ico--thm-alert',
  },
  tabs: {
    left: 's-ico--thm-angle-left',
    right: 's-ico--thm-angle-right',
    up: 's-ico--thm-angle-up',
    down: 's-ico--thm-angle-down',
  },
  table: {
    arrowUp: 's-ico--thm-arrow-up',
    warning: 's-ico--thm-alert',
    firstPage: 's-ico--thm-angle-double-left',
    prevPage: 's-ico--thm-angle-left',
    nextPage: 's-ico--thm-angle-right',
    lastPage: 's-ico--thm-angle-double-right',
  },
  tree: {
    icon: 's-ico--thm-control-play',
  },
  uploader: {
    done: 's-ico--thm-check',
    clear: 's-ico--thm-close',
    add: 's-ico--thm-support',
    upload: 's-ico--thm-cloud-up',
    removeQueue: 's-ico--thm-layout-media-right',
    removeUploaded: 's-ico--thm-layout-placeholder',
  },
};
