import { computed } from 'vue';

export const useSizeDefaults = {
  xs: 18,
  sm: 24,
  md: 32,
  lg: 38,
  xl: 46,
};

export const useSizeProps = {
  size: String,
};

export default function (props, sizes = useSizeDefaults) {
  // return sizeStyle
  return computed(() => (
    props.size !== void 0
      ? { fontSize: props.size in sizes ? `${sizes[props.size]}px` : props.size }
      : null
  ));
}
