import '../styles/style.scss';
import ProxyVue from '@common/src/proxyVue';
import componentsForm from '@common/components/Form';
import globalVariables from '@common/plugins/globalVariables';
import bootstrap from './bootstrap';
import components from './VueJS/components';
import bootApp from './bootstrap-app';
import legacyApps from './LegacyApps';

globalVariables();
function mixBoot(AppVue, withComponents = true) {
  bootstrap(AppVue);
  componentsForm(AppVue);
  if (withComponents) {
    components(AppVue);
  }
}

window.Vue = ProxyVue(mixBoot);

legacyApps(mixBoot);
bootApp(mixBoot);
