import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

export default (Vue, dsn, log = false) => {
  if (import.meta.env.PROD && import.meta.env.VITE_RELEASE_VERSION) {
    Sentry.init({
      dsn,
      release: import.meta.env.VITE_RELEASE_VERSION,
      integrations: [
        new Integrations.Vue({
          Vue,
          attachProps: true,
          consoleLog: log,
        }),
      ],
    });

    const userId = window?.core_project?.user_id;
    if (userId) {
      Sentry.setUser({ id: userId });
    }

    window.onerror = (message, url, row, col, error) => {
      Sentry.captureException(error);
      Sentry.configureScope((scope) => scope.clear());
      return true;
    };
  }
};
