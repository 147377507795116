import auth from '@common/src/Authentication';
import axios from 'axios';
import { App, ObjectPlugin } from 'vue';
import * as Sentry from '@sentry/browser';

export default {
  install(app: App) {
    auth.removeToken();
    app.config.globalProperties.http = axios;
    app.config.globalProperties.$http = axios;
    app.provide('http', axios);

    axios.interceptors.request.use((request) => {
      if (request?.body) {
        switch (request?.method) {
          case 'get': request.params = request.body; break;
          default: request.data = request.body; break;
        }
      }

      if (!request.url) {
        request.url = '#';
      }
      // request.credentials = true;//Нужно если будет CORS
      if (window.core_project.organizationId) {
        request.headers['X-Organization-Id'] = String(window.core_project.organizationId);
      }
      if (window.core_project.roleKey) {
        request.headers['X-Role-Key'] = String(window.core_project.roleKey);
      }
      if (window.core_project.locality) {
        request.headers['X-LocalityId'] = String(window.core_project.locality);
      }
      if (window.core_project.department) {
        request.headers['X-DepartmentId'] = String(window.core_project.department);
      }
      if (window.core_project.region) {
        request.headers['X-RegionId'] = String(window.core_project.region);
      }
      if (request.method.toLowerCase() !== 'get') {
        request.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
      }
      const token = auth.getToken();
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
      Sentry.setContext('requestData', { data: request.data });

      return request;
    });
    // outside state that lets us know if we need to refresh or not
    let allowRefresh = true;

    axios.interceptors.response.use(
      (res) => ({ ...res, ...res.response, body: res.data }),
      async (err) => {
        const { response } = err;
        const originalRequest = err.config;

        const errData = err.response.data;
        if (
          err.request.responseType === 'blob'
          && errData instanceof Blob
          && errData.type
          && errData.type.toLowerCase().indexOf('json') != -1
        ) {
          err.response.data = JSON.parse(await errData.text());
        }

        if (!response) {
          Sentry.captureException(err);
          Sentry.configureScope((scope) => scope.clear());
          throw err;
        }

        if (response.status === 401) {
        // we can only allow them to refresh once,
        // so turn this toggle off so they will be redirected if
        // the refresh response is also a 401
          if (allowRefresh) {
            allowRefresh = false;
            const data = await auth.refresh().catch((reject) => {
              if (reject.status === 419) {
                window.location.reload();
              }
            });
            auth.setToken(data.data.access_token);
            allowRefresh = true;

            originalRequest.headers.Authorization = `Bearer ${data.data.access_token}`;

            return axios(originalRequest);
          }
          return new Promise(((resolve) => {
            setTimeout(() => {
              const token = auth.getToken();
              originalRequest.headers.Authorization = `Bearer ${token}`;

              resolve(axios(originalRequest));
            }, 1000);
          }));
        }
        return Promise.reject({ ...err, ...err.response });
      },
    );
  },
} as ObjectPlugin;
