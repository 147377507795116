import { h, TransitionGroup } from 'vue';

export default {
  name: 'VNotify',

  data() {
    return {
      items: [],
      maxVisible: 3,
    };
  },

  beforeCreate() {

  },

  mounted() {
    this.handleBackendMessages();
    this.$eventHub.$on('v-notify-top-right', this.addNotify);
  },

  methods: {
    handleBackendMessages() {
      const message = {};
      if (window.core_project) {
        if (window.core_project.messages) {
          message.type = 'success';
          message.messages = window.core_project.messages;
          delete window.core_project.messages;
        } else if (window.core_project.errors) {
          message.type = 'error';
          message.messages = window.core_project.errors;
          delete window.core_project.errors;
        } else if (window.core_project.warns) {
          message.type = 'warn';
          message.messages = window.core_project.warns;
          delete window.core_project.warns;
        }
      }
      if (Object.keys(message).length !== 0) {
        if (Array.isArray(message.messages)) {
          message.messages.forEach((item) => {
            this.$notify({
              type: message.type,
              title: window.L.message,
              text: item,
            });
          });
        } else {
          this.$notify({
            type: message.type,
            title: window.L.message,
            text: message.messages,
          });
        }
      }
    },
    getTitleByType(type = '') {
      switch (type) {
        case 'error':
          return this.trans('notice.error');
        case 'warn':
          return this.trans('notice.warn');
        default:
          return this.trans('notice.message');
      }
    },
    addNotify(item) {
      if (this.items.length >= this.maxVisible) {
        this.removeNotify(0);
      }

      this.items.push({
        ...item,
        key: (`${Math.random()}`).substr(2, 18),
      });
    },

    removeNotify(index) {
      this.items.splice(index, 1);
    },

    createNotify(item, itemIdx) {
      return h('div', {
        key: item.text + item.key,
        class: ['sn-notify',
          {
            'sn-notify--success': item.type === 'success',
            'sn-notify--warning': item.type === 'warn',
            'sn-notify--error': item.type === 'error',
            'sn-notify--info': item.type === 'info',
          },
        ],
      }, [
        h('button', {
          class: 'sn-notify__close',
          onClick: () => this.removeNotify(itemIdx),
        }),
        h('div', { class: 'sn-notify__title' }, item.title ?? this.getTitleByType(item.type)),
        h('div', { class: 'sn-notify__text', innerHTML: item.text }),
      ]);
    },
  },

  render() {
    return h(TransitionGroup, {
      class: 'sn-notify__wrapper sn-notify__wrapper--pos-top-right',
      name: 'sn-transition--flip-right',
      tag: 'div',
    }, () => this.items.map((item, itemIdx) => {
      if (item.position === 'top-right') {
        return this.createNotify(item, itemIdx);
      }
    }));
  },
};
