import { h, computed } from 'vue';

import QIcon from '../icon/QIcon.js';

import { createComponent } from '../../utils/private.create/create.js';
import useCheckbox, { useCheckboxProps, useCheckboxEmits } from './use-checkbox.js';

const bgNode = h('div', {
  key: 'svg',
  class: 'sn-checkbox__bg s-pos-absolute',
}, [
  h('svg', {
    class: 'sn-checkbox__check sn--fit s-pos-absolute-full',
    viewBox: '0 0 24 24',
  }, [
    h('path', {
      fill: 'none',
      d: 'M1.73,12.91 8.1,19.28 22.79,4.59',
    }),

    // h('path', {
    //   class: 'sn-checkbox__indet s-pos-absolute',
    //   d: 'M4,14H20V10H4',
    // }),
  ]),
]);

export default createComponent({
  name: 'QCheckbox',

  props: useCheckboxProps,
  emits: useCheckboxEmits,

  setup(props) {
    function getInner(isTrue, isIndeterminate) {
      const icon = computed(() => (isTrue.value === true
        ? props.checkedIcon
        : (isIndeterminate.value === true
          ? props.indeterminateIcon
          : props.uncheckedIcon
        )
      ) || null);

      return () => (
        icon.value !== null
          ? [
            h('div', {
              key: 'icon',
              class: 'sn-checkbox sn--no-outline sn--row sn--inline sn--no-wrap',
            }, [
              h(QIcon, {
                class: 'sn-checkbox__icon',
                name: icon.value,
              }),
            ]),
          ]
          : [bgNode]
      );
    }

    return useCheckbox('checkbox', getInner);
  },
});
