import { defineAsyncComponent, createApp } from 'vue';

const VServiceLimitEditView = defineAsyncComponent(() => import('@app_mektep/views/VServiceLimitEditView'));

export default function (mixBoot) {
  if (document.getElementById('v-service-limit-edit-view')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-service-limit-edit-view', VServiceLimitEditView);
    VueApp.mount('#v-service-limit-edit-view');
    window.vServiceLimitEditView = VueApp;
  }
}
