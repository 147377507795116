import { defineAsyncComponent, createApp } from 'vue';

const VBooksAdditionRfid = defineAsyncComponent(() => import('@app_mektep/views/VBooksAdditionRfid/VBooksAdditionRfid.vue'));

export default function (mixBoot) {
  if (document.getElementById('v-books-addition-rfid-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-books-addition-rfid', VBooksAdditionRfid);
    VueApp.mount('#v-books-addition-rfid-app');
    window.VBooksAdditionRfidApp = VueApp;
  }
}
