<template>
  <div>
    <q-dialog
      v-for="(item, index) in modals"
      :key="index"
      v-model="modalsActive[index]"
      :persistent="!item.notPersistent"
      @hide="onHide(index)"
    >
      <VModal
        :size="item.size ? String(item.size) : 'medium'"
        :title="item.title ? item.title : ''"
        :in-body="item.inBody !== undefined ? item.inBody : true"
        :no-close="item.noClose ?? false"
      >
        <component
          :is="item.component"
          v-if="item.component"
          v-bind="item.binds ? item.binds : {}"
          v-on="componentEvents(item, index)"
        />
      </VModal>
    </q-dialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import _ from 'lodash';
import VModal from '@vjs/components/VModal';
import { QDialog } from '@quasar/components';
import { eventBus } from '@common/mixins';

export default defineComponent({
  name: 's-modal',
  components: { VModal, QDialog },
  data: () => ({ modalsActive: {} }),

  computed: {
    modals() { return this.$sModal.modals; },
  },

  watch: {
    modals: {
      handler(val) {
        _.forEach(val, (item, index) => {
          this.modalsActive[index] = true;
        });
      },
      deep: true,
    },
  },

  mounted() {
    eventBus.$on('onCloseAll', this.onHideAll);
    const criticalMessages = window.core_project?.critical_message;
    if (criticalMessages?.length) {
      _.forEach(criticalMessages, (itm, key) => {
        this.$sModal.open(`${itm.id}-${key}`, itm);
      });
    }
  },

  methods: {
    onClose(index) {
      setTimeout(() => {
        delete this.modalsActive[index];
      });
    },
    onHide(index) {
      this.$sModal.close(index);
      this.onClose(index);
    },
    onHideAll() {
      this.modalsActive = {};
      setTimeout(this.$sModal.closeAll, 300);
      // Таймаут 300мс, чтоб модалки успели закрыться с анимацией
      // без него все модалки кроме первой закрытой открываются заново (watch)
    },
    componentEvents(item, index) {
      const events = {
        onClose: () => this.onClose(index),
        onCloseAll: this.onHideAll,
      };

      if (item.events) {
        _.assign(events, item.events);
      }
      return events;
    },
  },
});
</script>
