import { computed } from 'vue';
// TODO: remove this
export const useDarkProps = {
  dark: {
    type: Boolean,
    default: false,
  },
};

export default function (props, $q) {
  // return isDark
  return computed(() => false);
}
