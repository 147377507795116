import GlobalVue from '@common/src/vue';
import { createReactivePlugin } from '@quasar/utils/private.create/create';

const Plugin = createReactivePlugin({ count: 0, qGlobalLoading: null }, {
  show() {
    if (!Plugin.qGlobalLoading.isActive) {
      const message = GlobalVue.trans.get('label.wait_for_load_loader');
      Plugin.qGlobalLoading.show({ message });
    }
    Plugin.count += 1;
  },
  hide(force = false) {
    Plugin.count -= 1;
    setTimeout(() => {
      if (force || Plugin.count <= 0) {
        if (Plugin.qGlobalLoading.isActive) {
          Plugin.qGlobalLoading.hide();
        }
        Plugin.count = 0;
      }
    }, 500);
  },
  install(app) {
    Plugin.qGlobalLoading = app.config.globalProperties.$q.loading;
    const { show, hide } = Plugin;

    const globalLoading = {
      show,
      hide,
    };

    app.config.globalProperties.$globalLoading = globalLoading;
    app.config.globalProperties.globalLoading = globalLoading;
    app.globalLoading = globalLoading;
    GlobalVue.globalLoading = globalLoading;
  },
});

export default Plugin;
