import {
  h, ref, computed, getCurrentInstance,
} from 'vue';

import useDark, { useDarkProps } from '../../composables/private.use-dark/use-dark.js';
import useRouterLink, { useRouterLinkProps } from '../../composables/private.use-router-link/use-router-link.js';

import { createComponent } from '../../utils/private.create/create.js';
import { hUniqueSlot } from '../../utils/private.render/render.js';
import { stopAndPrevent } from '../../utils/event/event.js';
import { isKeyCode } from '../../utils/private.keyboard/key-composition.js';

export default createComponent({
  name: 'QItem',

  props: {
    ...useDarkProps,
    ...useRouterLinkProps,

    tag: {
      type: String,
      default: 'div',
    },

    active: {
      type: Boolean,
      default: null,
    },

    clickable: Boolean,
    dense: Boolean,
    insetLevel: Number,

    tabindex: [String, Number],

    focused: Boolean,
    manualFocus: Boolean,
    bottomBordered: Boolean,
  },

  emits: ['click', 'keyup'],

  setup(props, { slots, emit }) {
    const { proxy: { $q } } = getCurrentInstance();

    const isDark = useDark(props, $q);
    const {
      hasLink, linkAttrs, linkClass, linkTag, navigateOnClick,
    } = useRouterLink();

    const rootRef = ref(null);
    const blurTargetRef = ref(null);

    const isActionable = computed(() => props.clickable === true
        || hasLink.value === true
        || props.tag === 'label');

    const isClickable = computed(() => props.disable !== true && isActionable.value === true);

    const classes = computed(() => `sn-item sn-item-type sn--row sn--no-wrap
    ${props.dense === true ? ' sn-item--dense' : ''}
    ${props.bottomBordered === true ? 'sn-item--border-bottom' : ''}
    ${isDark.value === true ? ' sn-item--dark' : ''

}${hasLink.value === true && props.active === null
  ? linkClass.value
  : (
    props.active === true
      ? ` sn-item--active${props.activeClass !== void 0 ? ` ${props.activeClass}` : ''}`
      : ''
  )
}${props.disable === true ? ' sn--disabled' : ''

}${isClickable.value === true
  ? ` sn-item--clickable sn-link sn--cursor-pointer ${
    props.manualFocus === true ? 'sn-manual-focusable' : 'sn-focusable sn-hoverable'
  }${props.focused === true ? ' sn-manual-focusable--focused' : ''}`
  : ''}`);

    const style = computed(() => {
      if (props.insetLevel === void 0) {
        return null;
      }

      const dir = $q.lang.rtl === true ? 'Right' : 'Left';
      return {
        [`padding${dir}`]: `${16 + props.insetLevel * 56}px`,
      };
    });

    function onClick(e) {
      if (isClickable.value === true) {
        if (blurTargetRef.value !== null) {
          if (e.qKeyEvent !== true && document.activeElement === rootRef.value) {
            blurTargetRef.value.focus();
          } else if (document.activeElement === blurTargetRef.value) {
            rootRef.value.focus();
          }
        }

        navigateOnClick(e);
      }
    }

    function onKeyup(e) {
      if (isClickable.value === true && isKeyCode(e, [13, 32]) === true) {
        stopAndPrevent(e);

        // for ripple
        e.qKeyEvent = true;

        // for click trigger
        const evt = new MouseEvent('click', e);
        evt.qKeyEvent = true;
        rootRef.value.dispatchEvent(evt);
      }

      emit('keyup', e);
    }

    function getContent() {
      const child = hUniqueSlot(slots.default, []);

      isClickable.value === true && child.unshift(
        h('div', { class: 'sn-focus-helper', tabindex: -1, ref: blurTargetRef }),
      );

      return child;
    }

    return () => {
      const data = {
        ref: rootRef,
        class: classes.value,
        style: style.value,
        role: 'listitem',
        onClick,
        onKeyup,
      };

      if (isClickable.value === true) {
        data.tabindex = props.tabindex || '0';
        Object.assign(data, linkAttrs.value);
      } else if (isActionable.value === true) {
        data['aria-disabled'] = 'true';
      }

      return h(
        linkTag.value,
        data,
        getContent(),
      );
    };
  },
});
