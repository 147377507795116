import UserEventBus from './UserEventBus';

export default {
  install(_Vue) {
    const eventBus = new UserEventBus();
    // eslint-disable-next-line no-param-reassign
    _Vue.config.globalProperties.$userEventBus = eventBus;
    // eslint-disable-next-line no-param-reassign
    _Vue.userEventBus = eventBus;
  },
};
