import { h, computed } from 'vue';

import { createComponent } from '../../utils/private.create/create.js';
import { hSlot } from '../../utils/private.render/render.js';

export default createComponent({
  name: 'QItemSection',

  props: {
    avatar: Boolean,
    thumbnail: Boolean,
    side: Boolean,
    top: Boolean,
    noWrap: Boolean,
  },

  setup(props, { slots }) {
    const classes = computed(() => 'sn-item__section column'
      + ` sn-item__section--${props.avatar === true || props.side === true || props.thumbnail === true ? 'side' : 'main'}${
        props.top === true ? ' sn-item__section--top justify-start' : ' sn--justify-center'
      }${props.avatar === true ? ' sn-item__section--avatar' : ''
      }${props.thumbnail === true ? ' sn-item__section--thumbnail' : ''
      }${props.noWrap === true ? ' sn-item__section--nowrap' : ''}`);

    return () => h('div', { class: classes.value }, hSlot(slots.default));
  },
});
