import { computed } from 'vue';

export const useTransitionProps = {
  transitionShow: {
    type: String,
    default: 'fade',
  },

  transitionHide: {
    type: String,
    default: 'fade',
  },

  transitionDuration: {
    type: [String, Number],
    default: 300,
  },
};

export default function (props, defaultShowFn = () => {}, defaultHideFn = () => {}) {
  return {
    transitionProps: computed(() => {
      const show = `sn-transition--${props.transitionShow || defaultShowFn()}`;
      const hide = `sn-transition--${props.transitionHide || defaultHideFn()}`;

      return {
        appear: true,

        enterFromClass: `${show}-enter-from`,
        enterActiveClass: `${show}-enter-active`,
        enterToClass: `${show}-enter-to`,

        leaveFromClass: `${hide}-leave-from`,
        leaveActiveClass: `${hide}-leave-active`,
        leaveToClass: `${hide}-leave-to`,
      };
    }),

    transitionStyle: computed(() => `--sn-transition-duration: ${props.transitionDuration}ms`),
  };
}
