import { defineAsyncComponent, createApp } from 'vue';

const VFormResetPassword = defineAsyncComponent(() => import('@common/views/FormResetPassword'));

export default function (mixBoot) {
  if (document.getElementById('v-reset-pass-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-form-reset-password', VFormResetPassword);
    VueApp.mount('#v-reset-pass-app');
    window.VFormResetPassword = VueApp;
  }
}
