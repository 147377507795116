import _ from 'lodash';

export default {
  getStep: (state) => (step = state.currentStep) => {
    const findRes = _.find(state.steps, (itm) => itm.step === step);

    return findRes || {};
  },
  getSelectedChild: (state) => state.selected_child,
};
