import { h, computed } from 'vue';

import { createComponent } from '../../utils/private.create/create.js';
import { hSlot } from '../../utils/private.render/render.js';

export default createComponent({
  name: 'QCardSection',

  props: {
    tag: {
      type: String,
      default: 'div',
    },

    horizontal: Boolean,
  },

  setup(props, { slots }) {
    const classes = computed(() => 'sn-card__section'
      + ` sn-card__section--${props.horizontal === true ? 'horiz row no-wrap' : 'vert'}`);

    return () => h(props.tag, { class: classes.value }, hSlot(slots.default));
  },
});
