export const quasarKey = '_q_';
export const timelineKey = '_q_t_';
export const stepperKey = '_q_s_';
export const layoutKey = '_q_l_';
export const pageContainerKey = '_q_pc_';
export const fabKey = '_q_f_';
export const formKey = '_q_fo_';
export const tabsKey = '_q_tabs_';
export const uploaderKey = '_q_u_';

export function emptyRenderFn() {}
