/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import FakeFile from '@common/src/FakeFile';
import AppVue from '@common/src/vue';
import base64Encode from './base64Encode';

class Helpers {
  urlGenerate(url, params) {
    let resultUrl = url;
    _.forEach(params, (value, key) => {
      resultUrl = resultUrl.replace(`:${key}`, value);
    });
    return resultUrl;
  }

  checkLoadingButton(button) {
    return button.classList.contains('loading-progress');
  }

  buttonLoader(button) {
    if (button.classList.contains('loading-progress')) {
      button.classList.remove('loading-progress');
    } else {
      button.classList.add('loading-progress');
    }
  }

  handlerResponseErrorNew(vm, res) {
    if (res.errorType === 422 || res.errorType === 423) {
      const errors = [];
      const formErrors = {};
      _.forEach(res.data.errors, (value, key) => {
        errors.push(value);
        _.set(formErrors, key, value);
      });
      vm.errors = formErrors;
      if (errors.length) {
        vm.$notify({
          text: errors.join('<br>'),
          type: 'error',
        });
      }
    }
  }

  showResponseErrors(errors = []) {
    if (errors.length === 0) {
      return;
    }
    let result = '';
    _.forEach(errors, (value) => {
      if (_.isArray(value)) {
        _.forEach(value, (message) => {
          result += `${message}<br/>`;
        });
      } else {
        result += `${value}<br/>`;
      }
    });
    AppVue.notify({
      type: 'error',
      text: result,
      duration: 5000,
    });
  }

  handlerResponseError({ data, status }, defaultCallback = () => {}) {
    if (!data && status === 200) {
      defaultCallback();
      return true;
    }
    if (status === 500) {
      AppVue.notify({
        text: data.message ?? this.trans('notice.error_on_server'),
        type: 'error',
      });
      return true;
    }
    if (status === 422 || status === 423) {
      this.showResponseErrors(data?.errors);
      return true;
    }
    if (data?.url_redirect) {
      window.onbeforeunload = null;
      window.location.href = data.url_redirect;
      return false;
    }
    if (data?.redirect !== undefined) {
      window.onbeforeunload = null;
      window.location.href = data.redirect;
      return false;
    }
    defaultCallback();
    return true;
  }

  showNotice(text, type = 'warn', title = window.L.error) {
    AppVue.$notify({
      type,
      title,
      text,
      duration: 5000,
    });
  }

  showErrors({ data, status }) {
    if (status === 422 || status === 423) {
      let errors = '';
      _.forEach(data.errors, (value) => {
        errors += `${value}<br/>`;
      });
      AppVue.$notify({
        type: 'error',
        title: window.L.error,
        text: errors,
        duration: 5000,
      });
      // show_notice(errors, 'error')
      return errors;
    }
    console.warn(data);

    return null;
  }

  appendArray(formData, values, name) {
    if (values === true) {
      formData.append(name, 1);
    } else if ([0, false].includes(values)) {
      formData.append(name, 0);
    } else if (!values && name) {
      formData.append(name, '');
    } else if (values instanceof FakeFile) {
      formData.append(name, values.file, values.name);
    } else if (typeof values === 'object') {
      _.forEach(values, (value, key) => {
        if (typeof value === 'object') {
          this.appendArray(formData, value, `${name}[${key}]`);
        } else if (value !== null) {
          if (value instanceof FakeFile) {
            formData.append(name, value.file, value.name);
          } else {
            formData.append(`${name}[${key}]`, value);
          }
        }
      });
    } else {
      formData.append(name, values);
    }
  }

  genFormDataArray(obj) {
    const formData = new FormData();
    Object.keys(obj).forEach((item) => {
      this.appendArray(formData, obj[item], item);
    });
    return formData;
  }

  getFileExtension(path) {
    const str = `${path}`;
    const dotP = str.lastIndexOf('.') + 1;
    const result = !dotP ? false : dotP !== str.length ? str.substr(dotP) : '';
    return result.toLowerCase();
  }

  base64Encode(stringToEncode) {
    return base64Encode(stringToEncode);
  }
}

const Helper = new Helpers();
export default Helpers;
export {
  Helper,
};
