import { h, computed, getCurrentInstance } from 'vue';

import useDark, { useDarkProps } from '../../composables/private.use-dark/use-dark.js';

import { createComponent } from '../../utils/private.create/create.js';
import { hSlot } from '../../utils/private.render/render.js';

export default createComponent({
  name: 'QCard',

  props: {
    ...useDarkProps,

    tag: {
      type: String,
      default: 'div',
    },

    square: Boolean,
    flat: Boolean,
    bordered: Boolean,
  },

  setup(props, { slots }) {
    const { proxy: { $q } } = getCurrentInstance();
    const isDark = useDark(props, $q);

    const classes = computed(() => `sn-card${
      isDark.value === true ? ' sn-card--dark sn-dark' : ''
    }${props.bordered === true ? ' sn-card--bordered' : ''
    }${props.square === true ? ' sn-card--square no-border-radius' : ''
    }${props.flat === true ? ' sn-card--flat no-shadow' : ''}`);

    return () => h(props.tag, { class: classes.value }, hSlot(slots.default));
  },
});
