import { h, computed, getCurrentInstance } from 'vue';

import { createComponent } from '../../utils/private.create/create.js';
import useDark, { useDarkProps } from '../../composables/private.use-dark/use-dark.js';
import { hSlot } from '../../utils/private.render/render.js';

export default createComponent({
  name: 'QList',

  props: {
    ...useDarkProps,

    bordered: Boolean,
    dense: Boolean,
    separator: Boolean,
    padding: Boolean,

    tag: {
      type: String,
      default: 'div',
    },
  },

  setup(props, { slots }) {
    const vm = getCurrentInstance();
    const isDark = useDark(props, vm.proxy.$q);

    const classes = computed(() => `sn-list${
      props.bordered === true ? ' sn-list--bordered' : ''
    }${props.dense === true ? ' sn-list--dense' : ''
    }${props.separator === true ? ' sn-list--separator' : ''
    }${isDark.value === true ? ' sn-list--dark' : ''
    }${props.padding === true ? ' sn-list--padding' : ''}`);

    return () => h(props.tag, { class: classes.value }, hSlot(slots.default));
  },
});
