import { h, computed } from 'vue';

import { createComponent } from '../../utils/private.create/create.js';
import { hSlot } from '../../utils/private.render/render.js';

export default createComponent({
  name: 'QItemLabel',

  props: {
    overline: Boolean,
    caption: Boolean,
    header: Boolean,
    lines: [Number, String],
  },

  setup(props, { slots }) {
    const parsedLines = computed(() => parseInt(props.lines, 10));

    const classes = computed(() => `sn-item__label${
      props.overline === true ? ' sn-item__label--overline text-overline' : ''
    }${props.caption === true ? ' sn-item__label--caption text-caption' : ''
    }${props.header === true ? ' sn-item__label--header' : ''
    }${parsedLines.value === 1 ? ' sn--ellipsis' : ''}`);

    const style = computed(() => (props.lines !== void 0 && parsedLines.value > 1
      ? {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': parsedLines.value,
      }
      : null));

    return () => h('div', {
      style: style.value,
      class: classes.value,
    }, hSlot(slots.default));
  },
});
