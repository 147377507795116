import { h } from 'vue';

export default {
  name: 'VFormInform',

  props: {
    message: { type: String, default: '' },
    useFaIcons: { type: Boolean, default: false },
    icon: { type: String, default: 'info-alt' },
    color: { type: String, default: '' },
  },

  render() {
    let tmpClass = 's-form__inform';
    if (this.color) {
      tmpClass += ` s-form__inform--${this.color}`;
    }
    return h('div', {
      class: tmpClass,
    }, [
      h('div', {
        class: `s-str-ico${this.useFaIcons ? '-fa' : ''} s-str-ico${this.useFaIcons ? '-fa' : ''}--thm-${this.icon}`,
      }, [
        h('div', {
          innerHTML: this.message,
        }),
      ]),
      this.$slots.default,
    ]);
  },
};
