import axios from 'axios';

export default {
  removeToken() {
    if (!window.core_project?.isAuth) {
      sessionStorage.removeItem('auth-token');
    }
  },
  setToken(token) {
    sessionStorage.setItem('auth-token', token);
  },
  getToken() {
    return sessionStorage.getItem('auth-token');
  },
  refresh() {
    return axios.post('/auth/token');
  },
  initRefreshToken() {
    return axios.post('/auth/init-refresh-token', [], {
      credentials: 'same-origin',
    });
  },
};
