import { createReactivePlugin } from '@quasar/utils/private.create/create';
import _ from 'lodash';
import GlobalVue from '@common/src/vue';

const Translator = createReactivePlugin(
  {},
  {
    trans(val = '', params) {
      let str = _.get(window.incraftLoadedTrans, val, val);
      if (_.isObject(params) && str !== undefined) {
        _.forEach(params, (value, key) => {
          str = str.replace(`:${key}`, value);
        });
        return str;
      }
      return str;
    },

    install(Vue) {
      Translator.Vue = Vue;
      if (Vue.config.globalProperties.$trans !== undefined) {
        return;
      }
      Vue.mixin({
        methods: {
          trans: Translator.trans,
        },
      });
      const trans = {
        add: () => undefined,
        get: Translator.trans,
      };
      Vue.config.globalProperties.$trans = trans;
      Vue.trans = trans;
      GlobalVue.trans = trans;
    },
  },
);

export default Translator;
