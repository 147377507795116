import { defineAsyncComponent } from 'vue';

const VLoginForm = defineAsyncComponent(() => import('@common/views/VLoginForm'));
const RegistrationPhoneForm = defineAsyncComponent(() => import('@common/views/FormRegistrationSms'));
const VOrganizationProfile = defineAsyncComponent(() => import('@common/views/VOrganizationProfile'));
const VOrganizationEditForm = defineAsyncComponent(() => import('@common/views/VOrganizationEditForm'));
const VOrganizationInfo = defineAsyncComponent(() => import('@common/views/VOrganizationInfo'));
const NewTableWithFilters = defineAsyncComponent(() => import('@common/views/NewTableWithFilters'));
const VSupportTeamButton = defineAsyncComponent(() => import('@vjs/balabaqsha/views/VSupportTeamButton'));
const VProfileForm = defineAsyncComponent(() => import('./Profile/Form'));
const VStatementServiceForm = defineAsyncComponent(() => import('./VStatementServiceForm'));
const VBinomConfirmationIinForm = defineAsyncComponent(() => import('./Binom/VConfirmationIinForm'));
const VContingentForm = defineAsyncComponent(() => import('./Contingent/Form'));
const VBookCreateForm = defineAsyncComponent(() => import('./VBookCreate/Form'));
const VElectronicContractCreate = defineAsyncComponent(() => import('./VElectronicContractCreate/Form'));
const VSchoolServiceAddressForm = defineAsyncComponent(() => import('./VSchoolServiceAddressForm/Form'));
const VLibraryStatementBookForm = defineAsyncComponent(() => import('./VLibraryStatementBookForm'));
const VLibraryBriefcaseForm = defineAsyncComponent(() => import('./VLibraryBriefcaseForm'));
const VLibraryBriefcaseContentForm = defineAsyncComponent(() => import('./VLibraryBriefcaseContentForm'));
const VElectronicContractConclude = defineAsyncComponent(() => import('./VElectronicContractConclude'));
const VElectronicContractShow = defineAsyncComponent(() => import('./VElectronicContractShow'));
const ParentStatementShow = defineAsyncComponent(() => import('./ParentStatementShow'));
const SubjectQuestions = defineAsyncComponent(() => import('./SubjectQuestions'));
const VMainPage = defineAsyncComponent(() => import('./VMainPage'));
const VPassportPageView = defineAsyncComponent(() => import('./VPassportPageView'));
const VBooksAdditionAlt = defineAsyncComponent(() => import('./VBooksAdditionAlt'));
const VLibraryStatementBookTable = defineAsyncComponent(() => import('./VLibraryStatementBookTable'));
const VInProgressAltBooksList = defineAsyncComponent(() => import('./VInProgressAltBooksList'));
const VLibraryActArrivalShow = defineAsyncComponent(() => import('./VLibraryActArrivalShow'));
const VLibraryActWriteOffShow = defineAsyncComponent(() => import('./VLibraryActWriteOffShow'));
const VLibraryActTransferShow = defineAsyncComponent(() => import('./VLibraryActTransferShow'));
const VMonitoringList = defineAsyncComponent(() => import('./VMonitoringList'));
const VLibraryDebitCreditMonitoring = defineAsyncComponent(() => import('./Monitorings/VLibraryDebitCreditMonitoring'));
const VMonitoringTree = defineAsyncComponent(() => import('./Monitorings/VMonitoringTree'));
const VLibraryStatementShow = defineAsyncComponent(() => import('./VLibraryStatementShow'));
const VLibraryBookPlansForm = defineAsyncComponent(() => import('./VLibraryBookPlansForm'));
const VRegistrationCheck = defineAsyncComponent(() => import('./VRegistrationCheck'));
const VOrganizationProfileAdditional = defineAsyncComponent(() => import('./VOrganizationProfileAdditional'));
const VSchoolCertificateStatementForm = defineAsyncComponent(() => import('./VSchoolCertificateStatementForm'));
const VPersonnelCardForm = defineAsyncComponent(() => import('./VPersonnelCardForm'));
const VLaborReportForm = defineAsyncComponent(() => import('./VLaborReportForm'));
const ProfileAvatar = defineAsyncComponent(() => import('./ProfileAvatar'));
const VMeetingsCalendar = defineAsyncComponent(() => import('./VMeetingsCalendar'));
const VCustomForm = defineAsyncComponent(() => import('./VCustomForm'));
const VOrgMTB = defineAsyncComponent(() => import('./VOrgMTB'));

export default function (Vue) {
  Vue.component('vue-registration-phone-form', RegistrationPhoneForm);
  Vue.component('vue-binom-confirmation-iin-form', VBinomConfirmationIinForm);
  Vue.component('v-login-form', VLoginForm);

  Vue.component('v-organization-profile', VOrganizationProfile);
  Vue.component('v-organization-info', VOrganizationInfo);
  Vue.component('v-organization-edit-form', VOrganizationEditForm);
  Vue.component('v-statement-service-form', VStatementServiceForm);
  Vue.component('v-profile-form', VProfileForm);
  Vue.component('vue-new-table-with-filters', NewTableWithFilters);
  Vue.component('v-contingent-form', VContingentForm);
  Vue.component('v-book-create-form', VBookCreateForm);
  Vue.component('v-electronic-contract-create', VElectronicContractCreate);
  Vue.component('v-school-service-address-form', VSchoolServiceAddressForm);
  Vue.component('v-library-statement-book-form', VLibraryStatementBookForm);
  Vue.component('v-library-briefcase-form', VLibraryBriefcaseForm);
  Vue.component('v-library-briefcase-content-form', VLibraryBriefcaseContentForm);
  Vue.component('v-electronic-contract-conclude', VElectronicContractConclude);
  Vue.component('v-electronic-contract-show', VElectronicContractShow);
  Vue.component('v-parent-statement-show', ParentStatementShow);
  Vue.component('v-subject-questions', SubjectQuestions);
  Vue.component('v-main-page', VMainPage);
  Vue.component('v-support-team-button', VSupportTeamButton);
  Vue.component('v-passport-page-view', VPassportPageView);
  Vue.component('v-books-addition-alt', VBooksAdditionAlt);
  Vue.component('v-library-statement-book-table', VLibraryStatementBookTable);
  Vue.component('v-in-progress-alt-books-list', VInProgressAltBooksList);
  Vue.component('v-library-act-arrival-show', VLibraryActArrivalShow);
  Vue.component('v-library-act-write-off-show', VLibraryActWriteOffShow);
  Vue.component('v-library-act-transfer-show', VLibraryActTransferShow);
  Vue.component('v-monitoring-list', VMonitoringList);
  Vue.component('v-library-debit-credit-monitoring', VLibraryDebitCreditMonitoring);
  Vue.component('v-monitoring-tree', VMonitoringTree);
  Vue.component('v-library-statement-show', VLibraryStatementShow);
  Vue.component('v-registration-check', VRegistrationCheck);
  Vue.component('v-library-book-plans-form', VLibraryBookPlansForm);
  Vue.component('v-organization-profile-additional', VOrganizationProfileAdditional);
  Vue.component('v-school-certificate-statement-form', VSchoolCertificateStatementForm);
  Vue.component('v-personnel-card-form', VPersonnelCardForm);
  Vue.component('v-labor-report-form', VLaborReportForm);
  Vue.component('vue-profile-avatar', ProfileAvatar);
  Vue.component('v-meetings-calendar', VMeetingsCalendar);
  Vue.component('v-custom-form', VCustomForm);
  Vue.component('v-org-mtb', VOrgMTB);
}
