import { defineAsyncComponent } from 'vue';
import Multiselect from 'vue-multiselect';
import {
  QSlideTransition,
  QField,
  QTooltip,
  QBtn,
  QDialog,
  QInput,
  QSelect,
} from '@quasar/components';
import { VFormInform } from '@vjs/components';

const VTwoFieldWrapper = defineAsyncComponent(() => import('@common/components/VTwoFieldWrapper'));
const EgovSign = defineAsyncComponent(() => import('@common/components/Modals/EgovSign'));
const VTreeSelectField = defineAsyncComponent(() => import('./VTreeSelectField'));
const WrapperMultiSelect = defineAsyncComponent(() => import('./WrapperMultiSelect'));
const DefaultField = defineAsyncComponent(() => import('./DefaultField'));
const DateField = defineAsyncComponent(() => import('./DateField'));
const TextField = defineAsyncComponent(() => import('./TextField'));
const TextShowField = defineAsyncComponent(() => import('./TextShowField'));
const CkeditorField = defineAsyncComponent(() => import('./CkeditorField'));
const NumberField = defineAsyncComponent(() => import('./NumberField'));
const RadioField = defineAsyncComponent(() => import('./RadioField'));
const BooleanField = defineAsyncComponent(() => import('./BooleanField'));
const VMultiSelectField = defineAsyncComponent(() => import('./VMultiSelectField'));
const SelectTimeField = defineAsyncComponent(() => import('./SelectTimeField'));
const PhoneField = defineAsyncComponent(() => import('./PhoneField'));
const IINField = defineAsyncComponent(() => import('./IINField'));
const SearchIINField = defineAsyncComponent(() => import('./SearchIINField'));
const ListStatementsField = defineAsyncComponent(() => import('./ListStatementsField'));
const BetweenField = defineAsyncComponent(() => import('./BetweenField'));
const SelectUserFileField = defineAsyncComponent(() => import('./SelectUserFileField'));
const EmptyField = defineAsyncComponent(() => import('./EmptyField'));

const MultipleFilesField = defineAsyncComponent(() => import('./MultipleFilesField'));
const FileField = defineAsyncComponent(() => import('./FileField'));
const PhotoField = defineAsyncComponent(() => import('./PhotoField'));
const TextAreaField = defineAsyncComponent(() => import('./TextAreaField'));
const DatePicker = defineAsyncComponent(() => import('./DatePicker'));
const VYearMonthPicker = defineAsyncComponent(() => import('./VYearMonthPicker'));
const WrapperMultiFileField = defineAsyncComponent(() => import('./WrapperMultiFileField'));
const WrapperFileField = defineAsyncComponent(() => import('./WrapperFileField'));
const VRelatedTextField = defineAsyncComponent(() => import('./VRelatedTextField'));
const WrapperCKEditorField = defineAsyncComponent(() => import('./WrapperCKEditorField'));
const SelectFromListCheckboxField = defineAsyncComponent(() => import('./SelectFromListCheckboxField'));
const WrapperTableMultiFileField = defineAsyncComponent(() => import('./WrapperTableMultiFileField'));

// Quasar form components
const QBetweenField = defineAsyncComponent(() => import('./QBetweenField'));
const QDateField = defineAsyncComponent(() => import('./QDateField'));
const QTimeField = defineAsyncComponent(() => import('./QTimeField'));
const QTextField = defineAsyncComponent(() => import('./QTextField'));
const QTextFieldRawControl = defineAsyncComponent(() => import('./QTextFieldRawControl'));
const QNumberField = defineAsyncComponent(() => import('./QNumberField'));
const QSelectField = defineAsyncComponent(() => import('./QSelectField'));
const WrapperQSelect = defineAsyncComponent(() => import('./WrapperQSelect'));
const WrapperQDate = defineAsyncComponent(() => import('./WrapperQDate'));
const WrapperQTime = defineAsyncComponent(() => import('./WrapperQTime'));
const WrapperQDateTime = defineAsyncComponent(() => import('./WrapperQDateTime'));
const WrapperQNumberInput = defineAsyncComponent(() => import('./WrapperQNumberInput'));
const QMultiCheckboxField = defineAsyncComponent(() => import('./QMultiCheckboxField'));
const VMultiQInputField = defineAsyncComponent(() => import('./VMultiQInputField'));
const QCheckboxField = defineAsyncComponent(() => import('./QCheckboxField'));
const QRadioField = defineAsyncComponent(() => import('./QRadioField'));
const VYearMonthPickerField = defineAsyncComponent(() => import('./VYearMonthPickerField'));

export default function (Vue) {
  Vue.component('v-tree-select-field', VTreeSelectField);
  Vue.component('v-multiselect', Multiselect);
  Vue.component('default-field', DefaultField);
  Vue.component('date-field', DateField);
  Vue.component('text-field', TextField);
  Vue.component('text-show-field', TextShowField);
  Vue.component('ckeditor-field', CkeditorField);
  Vue.component('number-field', NumberField);
  Vue.component('radio-field', RadioField);
  Vue.component('boolean-field', BooleanField);
  Vue.component('v-multi-select-field', VMultiSelectField);
  Vue.component('multiple-files-field', MultipleFilesField);
  Vue.component('file-field', FileField);
  Vue.component('photo-field', PhotoField);
  Vue.component('textarea-field', TextAreaField);
  Vue.component('v-wrapper-multi-select', WrapperMultiSelect);
  Vue.component('v-wrapper-table-multi-file-field', WrapperTableMultiFileField);

  Vue.component('between-field', BetweenField);

  Vue.component('select-time-field', SelectTimeField);
  Vue.component('phone-field', PhoneField);
  Vue.component('iin-field', IINField);
  Vue.component('search-iin-field', SearchIINField);
  Vue.component('list-statements-field', ListStatementsField);
  Vue.component('vue-date-picker', DatePicker);
  Vue.component('select-user-file-field', SelectUserFileField);
  Vue.component('v-year-month-picker', VYearMonthPicker);
  Vue.component('v-wrapper-multi-files-field', WrapperMultiFileField);
  Vue.component('v-wrapper-file-field', WrapperFileField);
  Vue.component('v-related-text-field', VRelatedTextField);
  Vue.component('v-wrapper-ckeditor-field', WrapperCKEditorField);
  Vue.component('v-select-from-list-checkbox-field', SelectFromListCheckboxField);
  Vue.component('v-empty-field', EmptyField);

  // Quasar form components
  Vue.component('q-between-field', QBetweenField);
  Vue.component('q-date-field', QDateField);
  Vue.component('q-time-field', QTimeField);
  Vue.component('q-select-field', QSelectField);
  Vue.component('v-wrapper-q-select', WrapperQSelect);
  Vue.component('v-wrapper-q-date', WrapperQDate);
  Vue.component('v-wrapper-q-time', WrapperQTime);
  Vue.component('v-wrapper-q-date-time', WrapperQDateTime);
  Vue.component('v-wrapper-q-number-input', WrapperQNumberInput);
  Vue.component('q-text-field', QTextField);
  Vue.component('q-text-field-raw-control', QTextFieldRawControl);
  Vue.component('q-number-field', QNumberField);
  Vue.component('q-multi-checkbox-field', QMultiCheckboxField);
  Vue.component('v-multi-q-input-field', VMultiQInputField);
  Vue.component('q-checkbox-field', QCheckboxField);
  Vue.component('q-radio-field', QRadioField);
  Vue.component('q-slide-transition', QSlideTransition);
  Vue.component('q-field', QField);
  Vue.component('q-tooltip', QTooltip);
  Vue.component('q-btn', QBtn);
  Vue.component('q-dialog', QDialog);
  Vue.component('q-input', QInput);
  Vue.component('q-select', QSelect);
  Vue.component('v-year-month-picker-field', VYearMonthPickerField);

  Vue.component('v-form-inform', VFormInform);
  Vue.component('v-two-field-wrapper', VTwoFieldWrapper);

  // Global Modal components
  Vue.component('v-modal-egov-sign', EgovSign);
}
