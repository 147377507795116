import { defineAsyncComponent, createApp } from 'vue';

const VMenu = defineAsyncComponent(() => import('@vjs/components/VMenu/VMenu.vue'));

export default function (mixBoot) {
  if (document.getElementById('v-menu-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-menu', VMenu);
    VueApp.mount('#v-menu-app');
    window.vMenuApp = VueApp;
  }
}
