import { defineAsyncComponent, createApp } from 'vue';

const VOrganizationsListView = defineAsyncComponent(() => import('@app_mektep/views/VOrganizationsListView'));

export default function (mixBoot) {
  if (document.getElementById('v-organizations-list-view')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('v-organizations-list-view', VOrganizationsListView);
    VueApp.mount('#v-organizations-list-view');
    window.vOrganizationsListView = VueApp;
  }
}
