import { defineAsyncComponent } from 'vue';

const CreateBookCopiesModal = defineAsyncComponent(() => import('./CreateBookCopies'));
const UserRejectStatement = defineAsyncComponent(() => import('./User/RejectStatement'));
const DeleteContingentModal = defineAsyncComponent(() => import('./DeleteContingentModal'));
const DeletePersonnelModal = defineAsyncComponent(() => import('./DeletePersonnelModal'));
const ScheduleExaminationModal = defineAsyncComponent(() => import('./ScheduleExamination'));
const Confirm = defineAsyncComponent(() => import('./Confirm'));
const ConfirmWithMessage = defineAsyncComponent(() => import('./ConfirmWithMessage'));
const ElectronicContractConfirm = defineAsyncComponent(() => import('./ElectronicContract/ElectronicContractConfirm'));
const FindBook = defineAsyncComponent(() => import('./IssuanceOfBooksModals/FindBookModal'));
const TableModal = defineAsyncComponent(() => import('./IssuanceOfBooksModals/TableModal'));
const ConfirmGiveBook = defineAsyncComponent(() => import('./IssuanceOfBooksModals/ConfirmGiveBookModal'));
const ChooseLanguage = defineAsyncComponent(() => import('./ElectronicContract/ChooseLanguage'));
const CreateLibraryActWriteOff = defineAsyncComponent(() => import('./CreateLibraryActWriteOff'));
const CreateLibraryActTransfer = defineAsyncComponent(() => import('./CreateLibraryActTransfer'));
const StatementSubscribe = defineAsyncComponent(() => import('./StatementSubscribe'));
const StatementReject = defineAsyncComponent(() => import('./StatementReject'));
const SignNcalayer = defineAsyncComponent(() => import('./SignNcalayer'));
const SelectUserFile = defineAsyncComponent(() => import('./SelectUserFiles'));
const ConfirmWithFieldsModal = defineAsyncComponent(() => import('./ConfirmWithFields'));
const InfoTableWithActions = defineAsyncComponent(() => import('./InfoTableWithActions'));
const PmpkConfirm = defineAsyncComponent(() => import('./PmpkConfirm'));

export default function (Vue) {
  Vue.component('v-model-user-reject-statement', UserRejectStatement);
  Vue.component('v-modal-delete-contingent', DeleteContingentModal);
  Vue.component('v-modal-delete-personnel', DeletePersonnelModal);
  Vue.component('v-modal-schedule-examination', ScheduleExaminationModal);
  Vue.component('v-modal-confirm', Confirm);
  Vue.component('v-modal-electronic-contract-confirm', ElectronicContractConfirm);
  Vue.component('v-modal-find-book', FindBook);
  Vue.component('v-modal-table', TableModal);
  Vue.component('v-modal-confirm-give-book', ConfirmGiveBook);
  Vue.component('v-modal-choose-language', ChooseLanguage);
  Vue.component('v-modal-create-library-act-write-off', CreateLibraryActWriteOff);
  Vue.component('v-modal-create-library-act-transfer', CreateLibraryActTransfer);
  Vue.component('v-modal-create-book-copies', CreateBookCopiesModal);
  Vue.component('v-modal-confirm-with-message', ConfirmWithMessage);
  Vue.component('v-modal-statement-subscribe', StatementSubscribe);
  Vue.component('v-modal-statement-reject', StatementReject);
  Vue.component('v-modal-sign-ncalayer', SignNcalayer);
  Vue.component('v-modal-select-user-files', SelectUserFile);
  Vue.component('v-confirm-with-fields', ConfirmWithFieldsModal);
  Vue.component('v-modal-info-table-with-actions', InfoTableWithActions);
  Vue.component('v-modal-pmpk-confirm', PmpkConfirm);
}
